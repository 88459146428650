var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.card["submit_status"] !== "not_stated"
    ? _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix box-card-date",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "span",
                { staticStyle: { float: "right", padding: "3px 0" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.dateFormat.from) +
                      " - " +
                      _vm._s(_vm.dateFormat.to) +
                      " "
                  ),
                ]
              ),
              _vm.dateFormat.note
                ? _c("span", [_vm._v(_vm._s(_vm.dateFormat.note))])
                : _vm._e(),
              _c("span", [_vm._v(" " + _vm._s(_vm.dateFormat.date) + " ")]),
            ]
          ),
          _c("div", { staticClass: "text card timeslot-list" }, [
            _c("span", { staticClass: "timeslot-list_card" }, [
              _vm._v("Терминал:"),
            ]),
            _c("span", { staticClass: "timeslot-list_meaning" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.card.timeslot
                      ? _vm.card.timeslot.unload_name
                      : _vm.card.unload_name
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("el-divider", { staticClass: "box-card_divider" }),
          _c("div", { staticClass: "text card timeslot-list" }, [
            _c("span", { staticClass: "timeslot-list_card" }, [
              _vm._v("Культура:"),
            ]),
            _c("span", { staticClass: "timeslot-list_meaning" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.card.timeslot
                      ? _vm.card.timeslot.culture_name
                      : _vm.card.culture_name
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("el-divider", { staticClass: "box-card_divider" }),
          _c("div", { staticClass: "text card timeslot-list" }, [
            _c("span", { staticClass: "timeslot-list_card" }, [
              _vm._v("Экспортер:"),
            ]),
            _c("span", { staticClass: "timeslot-list_meaning" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.card.timeslot
                      ? _vm.card.timeslot.exporter_name
                      : _vm.card.exporter_name
                  ) +
                  " "
              ),
            ]),
          ]),
          (
            _vm.card.timeslot
              ? _vm.card.timeslot.supplier_name
              : _vm.card.supplier_name
          )
            ? _c("el-divider", { staticClass: "box-card_divider" })
            : _vm._e(),
          (
            _vm.card.timeslot
              ? _vm.card.timeslot.supplier_name
              : _vm.card.supplier_name
          )
            ? _c("div", { staticClass: "text card timeslot-list" }, [
                _c("span", { staticClass: "timeslot-list_card" }, [
                  _vm._v("Поставщик:"),
                ]),
                _c("span", { staticClass: "timeslot-list_meaning" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.card.timeslot
                          ? _vm.card.timeslot.supplier_name
                          : _vm.card.supplier_name
                      ) +
                      " "
                  ),
                ]),
              ])
            : _vm._e(),
          (_vm.card.timeslot ? _vm.card.timeslot.num_auto : _vm.card.num_auto)
            ? _c("el-divider", { staticClass: "box-card_divider" })
            : _vm._e(),
          (_vm.card.timeslot ? _vm.card.timeslot.num_auto : _vm.card.num_auto)
            ? _c("div", { staticClass: "text card timeslot-list" }, [
                _c("span", { staticClass: "timeslot-list_card" }, [
                  _vm._v("Номер авто:"),
                ]),
                _c("span", { staticClass: "timeslot-list_meaning" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.card.timeslot
                          ? _vm.card.timeslot.num_auto
                          : _vm.card.num_auto
                      ) +
                      " "
                  ),
                ]),
              ])
            : _vm._e(),
          _c("el-divider", { staticClass: "box-card_divider" }),
          _c("div", { staticClass: "text card timeslot-list" }, [
            _c("span", { staticClass: "timeslot-list_card" }, [
              _vm._v("Статус:"),
            ]),
            _c("span", { staticClass: "timeslot-list_meaning" }, [
              _vm.isDeleted
                ? _c("span", { staticClass: "timeslot-list_deleted" }, [
                    _vm._v("УДАЛЕН"),
                  ])
                : _c("span", { staticClass: "timeslot-list_status" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.card.timeslot &&
                            _vm.timeslotStatuses &&
                            _vm.card.timeslot.truck_status &&
                            _vm.timeslotStatuses[_vm.card.timeslot.truck_status]
                              .title
                        ) +
                        " " +
                        _vm._s(
                          _vm.timeslotStatuses &&
                            _vm.card.truck_status &&
                            _vm.timeslotStatuses[_vm.card.truck_status].title
                        ) +
                        " "
                    ),
                  ]),
            ]),
          ]),
          _vm.showHandlerButtons
            ? _c("el-divider", { staticClass: "box-card_divider" })
            : _vm._e(),
          _vm.showHandlerButtons
            ? _c("div", { staticClass: "text card timeslot-list" }, [
                _c("span", { staticClass: "timeslot-list_card" }, [
                  _vm._v("Изменить:"),
                ]),
                _c(
                  "div",
                  { staticClass: "timeslot-list_meaning mod-flex" },
                  [
                    _vm.isShownShift && _vm.showShiftButton
                      ? _c(
                          "iq-button",
                          {
                            attrs: { color: "primary", size: "fullWidthSmall" },
                            on: { onClick: _vm.setNewTimeslotTime },
                          },
                          [
                            _c("i", { staticClass: "el-icon-time red-btn" }),
                            _vm._v(" Перенести "),
                          ]
                        )
                      : _vm._e(),
                    _vm.truckOnWay
                      ? _c(
                          "iq-button",
                          {
                            attrs: { color: "error", size: "fullWidthSmall" },
                            on: {
                              onClick: function ($event) {
                                _vm.card.timeslot
                                  ? _vm.$emit("openedDelete")
                                  : _vm.$emit("open")
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-delete red-btn" }),
                            _vm._v(" Удалить "),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.carNumber && _vm.card.timeslot
            ? _c("el-divider", { staticClass: "box-card_divider" })
            : _vm._e(),
          _vm.carNumber && _vm.card.timeslot
            ? _c("div", { staticClass: "text card timeslot-list" }, [
                _c("span", { staticClass: "timeslot-list_card" }, [
                  _vm._v("Данные:"),
                ]),
                _c(
                  "span",
                  { staticClass: "timeslot-list_meaning" },
                  [
                    _c(
                      "iq-button",
                      {
                        attrs: {
                          size: "fullWidthSmall",
                          color: _vm.carNumber ? "second" : "main",
                        },
                        on: {
                          onClick: function ($event) {
                            return _vm.$emit("routerPushTimeslot")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-truck icon-btn" }),
                        _vm._v(
                          _vm._s(
                            _vm.carNumber || " Введите данные автомобиля"
                          ) +
                            _vm._s(_vm.confirmId ? " - подтвержден" : "") +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.carNumber
            ? _c("el-divider", { staticClass: "box-card_divider" })
            : _vm._e(),
          _vm.carNumber && _vm.card.timeslot
            ? _c("div", { staticClass: "text card timeslot-list" }, [
                _c("span", { staticClass: "timeslot-list_card" }, [
                  _vm._v("Подтверждение:"),
                ]),
                _c(
                  "span",
                  { staticClass: "timeslot-list_meaning" },
                  [
                    _c(
                      "iq-button",
                      {
                        attrs: { size: "fullWidthSmall" },
                        on: {
                          onClick: function ($event) {
                            return _vm.$emit("submitedTimeslot")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-check icon-btn" }),
                        _vm._v(" Подтвердить тайм слот "),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }