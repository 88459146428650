<template>
  <el-card v-if="card['submit_status'] !== 'not_stated'" class="box-card">
    <div slot="header" class="clearfix box-card-date">
      <span style="float: right; padding: 3px 0">
        {{ dateFormat.from }} - {{ dateFormat.to }}
      </span>
      <span v-if="dateFormat.note">{{ dateFormat.note }}</span>
      <span>
        {{ dateFormat.date }}
      </span>
    </div>
    <div class="text card timeslot-list">
      <span class="timeslot-list_card">Терминал:</span>
      <span class="timeslot-list_meaning">
        {{ card.timeslot ? card.timeslot.unload_name : card.unload_name }}
      </span>
    </div>
    <el-divider class="box-card_divider" />

    <div class="text card timeslot-list">
      <span class="timeslot-list_card">Культура:</span>
      <span class="timeslot-list_meaning">
        {{ card.timeslot ? card.timeslot.culture_name : card.culture_name }}
      </span>
    </div>
    <el-divider class="box-card_divider" />

    <div class="text card timeslot-list">
      <span class="timeslot-list_card">Экспортер:</span>
      <span class="timeslot-list_meaning">
        {{ card.timeslot ? card.timeslot.exporter_name : card.exporter_name }}
      </span>
    </div>
    <el-divider
      v-if="card.timeslot ? card.timeslot.supplier_name : card.supplier_name"
      class="box-card_divider"
    />

    <div
      v-if="card.timeslot ? card.timeslot.supplier_name : card.supplier_name"
      class="text card timeslot-list"
    >
      <span class="timeslot-list_card">Поставщик:</span>
      <span class="timeslot-list_meaning">
        {{ card.timeslot ? card.timeslot.supplier_name : card.supplier_name }}
      </span>
    </div>
    <el-divider
      v-if="card.timeslot ? card.timeslot.num_auto : card.num_auto"
      class="box-card_divider"
    />
    <div
      v-if="card.timeslot ? card.timeslot.num_auto : card.num_auto"
      class="text card timeslot-list"
    >
      <span class="timeslot-list_card">Номер авто:</span>
      <span class="timeslot-list_meaning">
        {{ card.timeslot ? card.timeslot.num_auto : card.num_auto }}
      </span>
    </div>
    <el-divider class="box-card_divider" />

    <div class="text card timeslot-list">
      <span class="timeslot-list_card">Статус:</span>

      <span class="timeslot-list_meaning">
        <span v-if="isDeleted" class="timeslot-list_deleted">УДАЛЕН</span>
        <span v-else class="timeslot-list_status">
          {{
            card.timeslot &&
            timeslotStatuses &&
            card.timeslot.truck_status &&
            timeslotStatuses[card.timeslot.truck_status].title
          }}
          {{
            timeslotStatuses &&
            card.truck_status &&
            timeslotStatuses[card.truck_status].title
          }}
        </span>
      </span>
    </div>
    <el-divider v-if="showHandlerButtons" class="box-card_divider" />

    <div v-if="showHandlerButtons" class="text card timeslot-list">
      <span class="timeslot-list_card">Изменить:</span>

      <div class="timeslot-list_meaning mod-flex">
        <iq-button
          v-if="isShownShift && showShiftButton"
          color="primary"
          size="fullWidthSmall"
          @onClick="setNewTimeslotTime"
        >
          <i class="el-icon-time red-btn" />
          Перенести
        </iq-button>
        <iq-button
          v-if="truckOnWay"
          color="error"
          size="fullWidthSmall"
          @onClick="card.timeslot ? $emit('openedDelete') : $emit('open')"
        >
          <i class="el-icon-delete red-btn" />
          Удалить
        </iq-button>
      </div>
    </div>
    <el-divider v-if="carNumber && card.timeslot" class="box-card_divider" />
    <div v-if="carNumber && card.timeslot" class="text card timeslot-list">
      <span class="timeslot-list_card">Данные:</span>

      <span class="timeslot-list_meaning">
        <iq-button
          size="fullWidthSmall"
          :color="carNumber ? 'second' : 'main'"
          @onClick="$emit('routerPushTimeslot')"
        >
          <i class="el-icon-truck icon-btn" />{{
            carNumber || ' Введите данные автомобиля'
          }}{{ confirmId ? ' - подтвержден' : '' }}
        </iq-button>
      </span>
    </div>
    <el-divider v-if="carNumber" class="box-card_divider" />

    <div v-if="carNumber && card.timeslot" class="text card timeslot-list">
      <span class="timeslot-list_card">Подтверждение:</span>

      <span class="timeslot-list_meaning">
        <iq-button size="fullWidthSmall" @onClick="$emit('submitedTimeslot')">
          <i class="el-icon-check icon-btn" />
          Подтвердить тайм слот
        </iq-button>
      </span>
    </div>
  </el-card>
</template>

<script>
import {
  DATE_FORMAT,
  DATE_FORMAT_RU,
  GET_TIME,
  MILLISECOND_IN_2_HOURS,
} from '@/constants/date'
import { DEFAULT_TIMEZONE, diffTimeFromNowTz, textTimezones } from '@/core'
import { DR_GET_TERMINALS } from '@/store/actions'
import { mapGetters } from 'vuex'
import { timeslotStatuses } from '@/views/manager/data/manager'
import IqButton from '@/views/ui/components/buttons/IqButton'
import moment from '@/core/date'

export default {
  name: 'TimeSlotCard',
  components: {
    IqButton,
  },
  props: ['card', 'carNumber', 'confirmId'],
  data() {
    return {
      GET_TIME,
      DATE_FORMAT,
      DATE_FORMAT_RU,
      timeslotStatuses,
      showShiftButton: true,
      shiftButtonInterval: null,
    }
  },
  computed: {
    ...mapGetters({
      terminalsList: DR_GET_TERMINALS,
    }),
    isDeleted() {
      return Boolean(this.card.deleted_at)
    },
    isShownShift() {
      return diffTimeFromNowTz(this.card.window_from) < -MILLISECOND_IN_2_HOURS
    },
    // проверка на статус машины "В пути"
    truckOnWay() {
      return this.card.truck_status === 'not_stated'
    },
    // отображать ли раздел кнопок Перенести/Удалить
    showHandlerButtons() {
      return (
        !this.isDeleted &&
        ((this.isShownShift && this.showShiftButton) || this.truckOnWay)
      )
    },
    dateFormat() {
      let currentTerminal = this.terminalsList.find(
        item => item.id === this.card.unload_id,
      )

      moment.tz.setDefault(textTimezones[DEFAULT_TIMEZONE])
      let dateFromTz = moment(this.card.window_from).format('X')
      let dateToTz = moment(this.card.window_to).format('X')

      moment.tz.setDefault(textTimezones[currentTerminal.timezone_utc_offset])

      return {
        date: moment(dateFromTz, 'X').format(DATE_FORMAT_RU),
        from: moment(dateFromTz, 'X').format(GET_TIME),
        to: moment(dateToTz, 'X').format(GET_TIME),
        note:
          currentTerminal.timezone_utc_offset === DEFAULT_TIMEZONE
            ? ''
            : 'Местное время!',
      }
    },
  },
  mounted() {
    if (this.isShownShift) {
      this.shiftButtonInterval = setInterval(this.shiftButtonCheck, 5000)
    }
  },
  beforeDestroy() {
    if (this.shiftButtonInterval) {
      clearInterval(this.shiftButtonInterval)
    }
  },
  methods: {
    shiftButtonCheck() {
      if (diffTimeFromNowTz(this.card.window_from) > MILLISECOND_IN_2_HOURS) {
        this.showShiftButton = false
        clearInterval(this.shiftButtonInterval)
        this.shiftButtonInterval = null
      }
    },
    open(card) {
      this.$emit('open', card)
    },
    // нажатие кнопки "Перенести"
    setNewTimeslotTime() {
      this.$emit('setNewTimeslotTime')
    },
  },
}
</script>
<style lang="sass">
$bluColor: #4CA7D8
$redColor: #F56C6C
$mainFont: #606266

.box-card
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 6px
  background: #FFFFFF
  border: 1px solid #E4E7EA!important
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1)!important
  border-radius: 4px!important
  margin-bottom: 20px
  &_divider
    margin: 8px 0
    background: #E4E7ED
  &-date
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    & > span
      font-family: Roboto
      font-style: normal
      font-weight: 600
      font-size: 16px
      line-height: 22px
      font-feature-settings: 'tnum' on, 'lnum' on
      color: #606266
.el-card__header
  display: flex
  flex-direction: row
  align-items: center
  padding: 5px 10px

  background: #D3D4D6
  width: 100%
  padding: 8px 15px !important
  color: white
  border-radius: 4px
  color: #606266
.el-card__body
  width: 100%
  padding: 16px 5px!important

.timeslot-list
  width: 100%
  display: flex
  align-items: center
  flex-wrap: wrap
  justify-content: space-between
  &_card
    color: #303133
    font-weight: 600
    font-size: 14px
  &_meaning
    color: #303133
    font-weight: 400
    font-size: 14px
    &.mod-flex
      display: flex
  &_status
    width: 50%
    color: $bluColor
    font-weight: 800
    text-transform: uppercase
    size: 16px
  &_deleted
    width: 50%
    color: $redColor
    font-weight: 800
    text-transform: uppercase
    size: 16px
  .timeslot-list_item
    display: flex
    flex-direction: column
    font-weight: bold
    align-items: flex-end
    & > button
      margin-bottom: 12px
      width: 100%

  .timeslot-list_meaning
    text-align: right
.timeslot-circle-btn
  margin-top: 20px
  width: 40px
  height: 40px
  border-radius: 100px!important
  font-size: 18px!important
  .red-btn
    color: red
  .yellow-btn
    color: orange
  i
    @media only screen and (max-width: 550px)
      &:before
        width: 40px
        height: 40px
        border-radius: 100px!important
        font-size: 32px!important
        margin-left: 12px

.timeslot-list_number
  align-items: center
.timeslot-list_item-number
  font-size: 18px
  color: $bluColor
</style>
